<!-- Slider -->
<app-home-slider></app-home-slider>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{ display: displayStyle }">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <img src="assets/img/home/wcd.jpg" class="img-fluid" alt="Responsive image" />
      </div>
      <div class="modal-body">
        <p></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="closePopup()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Counter Section -->

<div class="container-fluid counter">
  <div class="container">
    <div class="row justify-content-center mycouter">
      <div class="col-sm countcol">
        <div class="text-center">
          <div class="counticon"><i class="fa fa-heartbeat"></i></div>
          <!-- <div class="countnum" data-target = '6000000'>85 </div> -->
          <span id="count1" class="display-4 countnum"></span>
          <span class="box first-box"></span>
          <div class="countdesc">Free Heart Surgeries</div>
        </div>
      </div>
      <div class="col-sm countcol">
        <div class="text-center">
          <div class="counticon"><i class="fas fa-first-aid"></i></div>
          <!-- <div class="countnum" data-target = '15'>18</div> -->
          <span id="count2" class="display-4 countnum"></span>
          <div class="countdesc">Free Medical Camps</div>
        </div>
      </div>
      <div class="col-sm countcol">
        <div class="text-center">
          <div class="counticon"><i class="fas fa-notes-medical"></i></div>
          <!-- <div class="countnum" data-target = '642'>642</div> -->
          <span id="count4" class="display-4 countnum"></span><span class="display-4"> +</span>
          <div class="countdesc">Free Dialysis</div>
        </div>
      </div>
      <div class="col-sm countcol">
        <div class="text-center">
          <div class="counticon"><i class="fab fa-pagelines"></i></div>
          <!-- <div class="countnum" data-target = '9'>1735</div> -->
          <span id="count3" class="display-4 countnum">+</span>
          <div class="countdesc">Sapling Planting</div>
        </div>
      </div>
      <div class="col-sm countcol">
        <div class="text-center">
          <div class="counticon2"><i class="fas fa-rupee-sign"></i></div>
          <!-- <div class="countnum" data-target = '90'>116</div> -->
          <!-- From 2005<br> -->
          <span id="count5" class="display-4 countnum"></span><span class="crore">cr</span><span class="display-4">
            +</span>
          <div class="countdesc">Treatment aid</div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Welcome section -->

<div class="container welcomesec">
  <div class="toppad">
    <div class="row">
      <div class="col-sm-5" style="padding-right: 35px">
        <h2>WELCOME TO NIMS <span style="color: #d72639">CHARITY</span></h2>
        <p style="text-align: justify">
          NIMS Medicity has always strived hard to live up to its mission of
          “providing high-quality integrated health care with unrelenting
          attention to clinical excellence, patient safety and unparalleled
          passion and commitment, assuring access for the entire society to
          quality health care, regardless of ability to pay”. Commitment towards
          helping the needy ones and activities towards societal change has
          always been the bedrock on which the activities of NIMS Medicity have
          always built upon.
        </p>
        <p style="text-align: justify">
          NIMS charity works in communities around the world. They are committed
          to being there until local people no longer need their help. What's
          more, local people decide which projects will give them the best
          chance of changing their lives for the better.
        </p>
        <div class="row">
          <div class="col-sm">
            <ul>
              <li>
                <a href="/charity/spectrum-child-development-research-center" style="color: black">Spectrum</a>
              </li>
              <li>
                <a href="/charity/youth-red-cross" style="color: black">Youth Red Cross</a>
              </li>
              <li>
                <a href="/charity/free-civil-service-coaching" style="color: black">Free Civil Service Coaching</a>
              </li>
              <li>
                <a href="/charity/free-hearing-aid" style="color: black">Hearing Aid</a>
              </li>
              <li>
                <a href="/charity/free-dialysis" style="color: black">Free Dialysis</a>
              </li>
              <li>
                <a href="/charity/Nutritious-food-for-cancer-patients" style="color: black">Amulya (Daily milk
                  distribution since 2012)</a>
              </li>
              <li>
                <a href="/charity/shayadri" style="color: black">Sahyadri</a>
              </li>
              <li>
                <a href="/charity/free-medical-camps" style="color: black">Free Medical Camps</a>
              </li>
              <li>
                <a href="/charity/Neyyattinkara-Pension-Plan-Free-pension-for-Differently-abled-children"
                  style="color: black">Neyyattinkara Pension Plan (Free pension for Differently
                  abled children)</a>
              </li>
            </ul>
          </div>
          <div class="col-sm">
            <ul>
              <li>
                <a href="/charity/nims-khadi-movement" style="color: black">NIMS Khadi Movement</a>
              </li>
              <li>
                <a href="/charity/heart-to-heart" style="color: black">Heart to Heart</a>
              </li>
              <li>
                <a href="/charity/education-scholarship-under-niu" style="color: black">Education Scholarship Under
                  NIU</a>
              </li>
              <li>
                <a href="/charity/sapling-for-new-born-baby" style="color: black">Sapling For a Newborn</a>
              </li>
              <li>
                <a href="/charity/harithakam" style="color: black">Harithakam</a>
              </li>
              <li>
                <a href="/charity/annapoorna" style="color: black">NIMS Annapoorna (Associated with Triveni, Govt of
                  Kerala)</a>
              </li>
              <li>
                <a href="/charity/padanolsavam" style="color: black">Padanolsavam</a>
              </li>
              <li><a href="/charity/Tiger" style="color: black">Tiger</a></li>
              <li>
                <a href="/charity/nano-clinic" style="color: black">Nano Clinic</a>
              </li>
            </ul>
          </div>
        </div>
        <!-- <div class="row">  
                <div class="col">   
                    <ul>
                        <li>Free Heart Surgery</li>
                        <li>Hearing Aid</li>
                        <li>Free Dialysis</li>
                      </ul>
                </div>
                <div class="col">
                    <ul>
                        
                        <li>Sapling For a Newborn</li>
                        <li>Special Children Treatment</li>
                      </ul>
                </div>
            </div> -->
        <a class="btn btn-primary" href="/charity" role="button">View Details</a>
      </div>

      <div class="col-sm-7">
        <div class="row">
          <div class="col-6">
            <div class="row borderstyle">
              <!-- <div class="col-sm-3">
                <div class="text-center">
                  <div class="counticon"><i class="fa fa-heartbeat"></i></div>
                </div>
              </div> -->
              <div class="col-sm">
                <div class="serviceimg">
                  <img src="assets/img/home/spectrumlogo.png" class="img-fluid serviceimgedit" alt="Responsive image" />
                </div>
                <h6>CHILD DEVELOPMENT RESEARCH CENTRE</h6>
                <p>
                  It is estimated that India has around 80 million people with
                  disability, which may be age related ...
                  <a href="/charity/spectrum-child-development-research-center" style="color: #d72639">Know More</a>
                </p>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row borderstyle">
              <!-- <div class="col-sm-3">
                <div class="text-center">
                  <div class="counticon"><i class="fa fa-heartbeat"></i></div>
                </div>
              </div> -->
              <div class="col-sm">
                <div class="serviceimg">
                  <img src="assets/img/home/hearttoheart.png" style="width: 83px" class="img-fluid"
                    alt="Responsive image" />
                </div>
                <h6>HEART TO HEART</h6>
                <p>
                  Under this project, NIMS hospital has been providing free
                  heart surgeries to those who are under the poverty line....
                  <a href="/charity/heart-to-heart" style="color: #d72639">Know More</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="row borderstyle">
              <!-- <div class="col-sm-3">
                <div class="text-center">
                  <div class="counticon"><i class="fa fa-heartbeat"></i></div>
                </div>
              </div> -->
              <div class="col-sm">
                <div class="serviceimg">
                  <img src="assets/img/home/Niu.png" style="width: 110px" class="img-fluid" alt="Responsive image" />
                </div>
                <h6>EDUCATION SCHOLARSHIP UNDER NIU</h6>
                <p>
                  Noorul Islam Civil Service Academy is a school-student level
                  Civil service programme named....
                  <a href="/charity/education-scholarship-under-niu" style="color: #d72639">Know More</a>
                </p>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row borderstyle">
              <!-- <div class="col-sm-3">
                <div class="text-center">
                  <div class="counticon"><i class="fa fa-heartbeat"></i></div>
                </div>
              </div> -->
              <div class="col-sm">
                <div class="serviceimg">
                  <img src="assets/img/home/civil.png" style="width: 110px" class="img-fluid" alt="Responsive image" />
                </div>
                <h6>FREE CIVIL SERVICE COACHING</h6>
                <p>
                  Noorul Islam Civil Service Academy is a school-student level
                  Civil service programme named....
                  <a rout href="/charity/free-civil-service-coaching" style="color: #d72639">Know More</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- What we do -->
<div class="container-fluid contactus">
  <div class="container">
    <div class="subheading">
      <h4 class="text-uppercase font-weight-bold">
        HOW CAN <span style="color: #d72639">HELP ?</span>
      </h4>
      <p class="whatwedosub">
        NIMS charity works in communities around the world. They are committed
        to being there until local people no longer need their help. What's
        more, local people decide which projects will give them the best chance
        of changing their lives for the better.
      </p>
    </div>
    <div class="row whatwedo">
      <div class="col-sm">
        <div class="card">
          <div class="effect-image-1 opacity-effect">
            <img class="card-img-top" src="assets/img/home/dialysis.jpg" alt="Card image cap" />
          </div>
          <div class="card-body cardbodystyle">
            <h5 class="card-title">FREE DIALYSIS FOR POOR PATIENTS</h5>
          </div>
        </div>
      </div>
      <div class="col-sm">
        <div class="card">
          <div class="effect-image-1 opacity-effect">
            <img class="card-img-top" src="assets/img/home/saplings.jpg" alt="Card image cap" />
          </div>
          <div class="card-body cardbodystyle">
            <h5 class="card-title">SAPLING FOR A NEW BORN BABY</h5>
          </div>
        </div>
      </div>
      <div class="col-sm">
        <div class="card">
          <div class="effect-image-1 opacity-effect">
            <img class="card-img-top" src="assets/img/home/img1.jpg" alt="Card image cap" />
          </div>
          <div class="card-body cardbodystyle">
            <h5 class="card-title">FREE MEDICINE FOR HIV PATIENTS</h5>
          </div>
        </div>
      </div>
      <div class="col-sm">
        <div class="card">
          <div class="effect-image-1 opacity-effect">
            <img class="card-img-top" src="assets/img/home/cancerpatientdist1.jpg" alt="Card image cap" />
          </div>
          <div class="card-body cardbodystyle">
            <h5 class="card-title">NUTRITIOUS FOOD FOR CANCER PATIENTS</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Gallery & upcoming section -->

<div class="container">
  <div class="row gall">
    <div class="col-sm-7">
      <h4 class="text-uppercase font-weight-bold">
        OUR <span style="color: #d72639">GALLERY</span>
      </h4>
      <hr style="width: 10%; text-align: left; margin-left: 0" />
      <app-common-gallery [getFilesPath]="imagePath"></app-common-gallery>
    </div>
    <div class="col-sm-5">
      <h4 class="text-uppercase font-weight-bold">
        UPCOMING<span style="color: #d72639"> EVENTS</span>
      </h4>
      <hr style="width: 10%; text-align: left; margin-left: 0" />
      <div class="row upcomingfull">
        <div class="col-4">
          <img style="width: 120px; height: 120px" src="assets/img/Pastevents/12-08-20241.jpg" alt="..." class="" />
        </div>
        <div class="col-8 upcomingcontent">
          <h5>സൗജന്യ ഏകദിന മെഡിക്കൽ ക്യാമ്പ് &#64; ബാലരാമപുരം   </h5>
          <div class="eventschedule">
            <p>
              <i class="fa fa-calendar" aria-hidden="true"></i> 12-08-2024
              &nbsp;&nbsp;&nbsp;| &nbsp;&nbsp;&nbsp;<i class="fa fa-map-marker" aria-hidden="true"></i>
              ബാലരാമപുരം   
            </p>
          </div>
          <div class="d-none d-md-block">
            <!-- <p></p> -->
          </div>
        </div>
      </div>
      <div class="row upcomingfull">
        <div class="col-4">
          <img style="width: 120px; height: 120px" src="assets/img/Pastevents/12-08-2024.jpg" alt="..." class="" />
        </div>
        <div class="col-8 upcomingcontent">
          <h5>സൗജന്യ ഏകദിന മെഡിക്കൽ ക്യാമ്പ് &#64; വള്ളക്കടവ് 
          </h5>
          <div class="eventschedule">
            <p>
              <i class="fa fa-calendar" aria-hidden="true"></i> 12-08-2024
              &nbsp;&nbsp;&nbsp;| &nbsp;&nbsp;&nbsp;<i class="fa fa-map-marker" aria-hidden="true"></i>
              വള്ളക്കടവ് 
            </p>
          </div>
          <div class="d-none d-md-block">
            <!-- <p></p> -->
          </div>
        </div>
      </div>
      <div class="row upcomingfull">
        <div class="col-4">
          <img style="width: 120px; height: 120px" src="assets/img/Pastevents/11-27-2024.jpg" alt="..." class="" />
        </div>
        <div class="col-8 upcomingcontent">
          <h5>സൗജന്യ ഏകദിന മെഡിക്കൽ ക്യാമ്പ് &#64; തിരുവനന്തപുരം   </h5>
          <div class="eventschedule">
            <p>
              <i class="fa fa-calendar" aria-hidden="true"></i> 11-27-2024
              &nbsp;&nbsp;&nbsp;| &nbsp;&nbsp;&nbsp;<i class="fa fa-map-marker" aria-hidden="true"></i>
              തിരുവനന്തപുരം   
            </p>
          </div>
          <div class="d-none d-md-block">
            <!-- <p></p> -->
          </div>
        </div>
      </div>
      <div class="row upcomingfull">
        <div class="col-4">
          <img style="width: 120px; height: 120px" src="assets/img/Pastevents/11-27-20241.jpg" alt="..." class="" />
        </div>
        <div class="col-8 upcomingcontent">
          <h5>സൗജന്യ ഏകദിന മെഡിക്കൽ ക്യാമ്പ് &#64;  നെയ്യാറ്റിൻകര   </h5>
          <div class="eventschedule">
            <p>
              <i class="fa fa-calendar" aria-hidden="true"></i>11-27-2024
              &nbsp;&nbsp;&nbsp;| &nbsp;&nbsp;&nbsp;<i class="fa fa-map-marker" aria-hidden="true"></i>
              നെയ്യാറ്റിൻകര 
            </p>
          </div>
          <div class="d-none d-md-block">
            <!-- <p></p> -->
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>

<!-- video section -->

<div class="container-fluid videosection">
  <div class="container">
    <div class="row gall">
      <div class="col-sm">
        <h4 class="text-uppercase font-weight-bold vidtitle" style="color: white">
          NIMS helping Tsunami victims at Nagapattinam
        </h4>
        <p style="color: white; text-align: justify">
          <!-- Lorem ipsum dolor sit amet, consectetur adipisicing elit.Earum quos
          quae, provident aspernatur et voluptatibus saepe rec usandae accusamus
          sit non, numquam pariatur odit veniam aut andae accusamus sit non,
          num. -->
        </p>
        <button type="button" class="btn btn-light vid button">
          Watch More
        </button>
      </div>
      <div class="col-sm">
        <div class="embed-responsive embed-responsive-16by9">
          <iframe src="https://www.youtube.com/embed/-vhlxfgICq8" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Recent News -->

<div class="container">
  <div class="toppad">
    <div>
      <h4 class="text-uppercase font-weight-bold" style="text-align: center">
        RECENT <span style="color: #d72639">NEWS</span>
      </h4>
      <p style="text-align: center">
        <!-- Lorem ipsum dolor sit amet, consectetur adipisicing elit.<br />Rem autem
        voluptatem obcaecati! -->
      </p>
    </div>

    <div class="row">
      <div class="col-sm-4">
        <div class="card cd">
          <img class="card-img-top cdimptop" src="assets/img/Pastevents/12-08-20241.jpg" alt="Card image cap" />
          <p class="newstime">
            <i class="fa fa-calendar" aria-hidden="true"></i>12-08-2024
            &nbsp;&nbsp;&nbsp;| &nbsp;&nbsp;&nbsp;<i class="fa fa-map-marker" aria-hidden="true"></i>
            ബാലരാമപുരം 
          </p>
          <div class="card-body cdbody">
            <h5 class="card-title cdtitle">
              സൗജന്യ ഏകദിന മെഡിക്കൽ ക്യാമ്പ് &#64; ബാലരാമപുരം  .
            </h5>
            <p class="card-text cdtext">
              നിംസ് മെഡിസിറ്റിയുടെ നേതൃത്വത്തിൽ ഗ്രീൻ സിറ്റി ലയൺസ്‌ ക്ലബ്ബിന്റെ ആഭിമുഖ്യത്തിൽ സൗജന്യ മെഡിക്കൽ ക്യാമ്പ് സംഘടിപ്പിച്ചു.
            </p>
          </div>
        </div>
      </div> 
      <div class="col-sm-4">
        <div class="card cd">
          <img class="card-img-top cdimptop" src="assets/img/Pastevents/12-08-2024.jpg" alt="Card image cap" />
          <p class="newstime">
            <i class="fa fa-calendar" aria-hidden="true"></i>12/08/2024
            &nbsp;&nbsp;&nbsp;| &nbsp;&nbsp;&nbsp;<i class="fa fa-map-marker" aria-hidden="true"></i>
            വള്ളക്കടവ്  
          </p>
          <div class="card-body cdbody">
            <h5 class="card-title cdtitle">
              സൗജന്യ ഏകദിന മെഡിക്കൽ ക്യാമ്പ് &#64; വള്ളക്കടവ്   
            </h5>
            <p class="card-text cdtext">
              നിംസ് മെഡിസിറ്റിയുടെ നേതൃത്വത്തിൽ ഗ്ലോബൽ വെൽഫയർ ഫൗണ്ടേഷൻ ഓഫ് ഇന്ത്യ വേൾഡ് ഫോറം, വെള്ളയമ്പലം ടി എം സി ഇൻസ്റ്റിറ്റ്യൂട്ട് എന്നിവയുടെ സംയുക്താഭിമുഖ്യത്തിൽ സൗജന്യ മെഡിക്കൽ ക്യാമ്പ് സംഘടിപ്പിച്ചു
            </p>
          </div>
        </div>
      </div> 
      <div class="col-sm-4">
        <div class="card cd">
          <img class="card-img-top cdimptop" src="assets/img/Pastevents/11-27-2024.jpg" alt="Card image cap" />
          <p class="newstime">
            <i class="fa fa-calendar" aria-hidden="true"></i>11-27-2024
            &nbsp;&nbsp;&nbsp;| &nbsp;&nbsp;&nbsp;<i class="fa fa-map-marker" aria-hidden="true"></i>
            തിരുവനന്തപുരം  
          </p>
          <div class="card-body cdbody">
            <h5 class="card-title cdtitle">
              സൗജന്യ ഏകദിന മെഡിക്കൽ ക്യാമ്പ് &#64; തിരുവനന്തപുരം .
            </h5>
            <p class="card-text cdtext">
              പതിനാലാമത് സംസ്ഥാന പാരാ അത്‌ലറ്റിക് ചാമ്പ്യൻഷിപ്പിൽ  നിംസ് മെഡിസിറ്റി ക്രമീകരിച്ച സൗജന്യ മെഡിക്കൽ ക്യാമ്പ്
              കഴിഞ്ഞ രണ്ട് ദിവസങ്ങളിലായി നടന്ന് വന്നിരുന്ന മത്സരത്തിൽ നിംസ് മെഡിസിറ്റി സൗജന്യ മെഡിക്കൽ ടീമും എമർജൻസി സർവീസും ക്രമീകരിച്ചിരുന്നു
            </p>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>

<!-- Testimonials -->
<div class="container">
  <section class="pt-5 pb-5">
    <div class="container">
      <div class="row">
        <div class="col-6">
          <!-- <h3 class="mb-3">Patients who availed Free Heart Surgery</h3> -->
          <!-- <h4 class="mb-3">Patients who availed Free Heart Surgery</h4> -->
          <h4 class="text-uppercase font-weight-bold">
            Patients availed
            <span style="color: #d72639">Free Heart Surgery</span>
          </h4>
          <hr style="width: 10%; text-align: left; margin-left: 0" />
        </div>
        <div class="col-6 text-right">
          <a class="btn btn-primary mb-3 mr-1" href="#carouselExampleIndicators2" role="button" data-slide="prev">
            <i class="fa fa-arrow-left"></i>
          </a>
          <a class="btn btn-primary mb-3" href="#carouselExampleIndicators2" role="button" data-slide="next">
            <i class="fa fa-arrow-right"></i>
          </a>
        </div>
        <div class="col-12">
          <div id="carouselExampleIndicators2" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\murali.jpg" alt="" />
                      <div class="name">Mr.Murali</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>58</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            Kalachedhana Harisree
                            Maradu PO
                            Ernakulam 
                          </td>
                        </tr>
                        <tr>
                          <td>Date of Admission</td>
                          <td>08/05/2024</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>11/05/2024</td>
                        </tr>
                        <tr>
                          <td>Date of Discharge</td>
                          <td>21/05/2024</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\sakhersha.jpg" alt="" />
                      <div class="name">Mr.Sakkersha.Y</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>62</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            Sargam Chiratgalakkal, Venjaramood 
                          </td>
                        </tr>
                        <tr>
                          <td>Date of Admission</td>
                          <td>24.06.2023</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>27.06.2023</td>
                        </tr>
                        <tr>
                          <td>Date of Discharge</td>
                          <td>14.07.2023</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\moni.jpg" alt="" />
                      <div class="name">Mrs. Moni.M</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>55</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            Alumvilayil, Parakoode.P.O, Pathanamthitta, Kerala.
                          </td>
                        </tr>
                        <tr>
                          <td>Date of Admission</td>
                          <td>07.09.2024</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>09.09.2024</td>
                        </tr>
                        <tr>
                          <td>Date of Discharge</td>
                          <td>14.09.2024</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\naushad.jpg" alt="" />
                      <div class="name">Naushad</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>53</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            Thekkekunnam, Odayam, Varkala, Thiruvananthapuram
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>Open Heart Surgery</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>08/11/2021</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\Firoz.png" alt="" />
                      <div class="name">Firoz Khan</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>38</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            Darul Huda, Meenara, PirappancodeP.O,
                            Thiruvananthapuram.
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>Open Heart Surgery</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>09.04.2021</td>
                        </tr>
                        <tr>
                          <td>Discharge Date</td>
                          <td>19.04.2021</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">NISAR</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>42</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            KADAYIL PUTTHAN VEEDU PERAYAM, UMAYANALUR PO KOLLAM
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CAG</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>30.09.2011</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\athira.jpg" alt="" />
                      <div class="name">Athira</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>23</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            Kurumbolin, Akkalbhagam, Panamala,
                            Edappallykotta.P.O, Chavara, Kollam
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets/img/home/patients/akhil.jpg" alt="" />
                      <div class="name">Akhil A Kumar</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>15</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            Charuvila veedu, Kayattukonam, Kilimanoor P O,
                            Trivandrum-695601
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\younus.jpg" alt="" />
                      <div class="name">Yoonus T J</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>49</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            Puthenpura Parambil Veedu, Sastha Temple Road,
                            Kalloor P O, Kochi-17
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\Raji.jpg" alt="" />
                      <div class="name">Rija</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>11</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>Palathadathil veedu, Edaikadu p.o, Kollam</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\nizarudeenk.jpg" alt="" />
                      <div class="name">NIZARUDEEN K</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>55</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            Puthuvalputhanveedu, Manikam vilakom, Poonthura P O
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG X 2</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>17/01/2014</td>
                        </tr>
                        <tr>
                          <td>Discharge Name</td>
                          <td>27/01/2014</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\12.jfif" alt="" />
                      <div class="name">ASHKAR</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>SEENAHOUSE PALLINADA, KOLLAM CODE</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>8/10/2009</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\sainaba.jpg" alt="" />
                      <div class="name">Sainaba</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>62</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>HO 7/277, Kochangadi, Ernakulam</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Suhara.K</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>55</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3"></div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\sharaha.jpg" alt="" />
                      <div class="name">Sharahabeel.A</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>52</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            Charuvila Puthen veedu, Pallickal.P.O, Kilimanoor,
                            Trivandrum
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\sheeba1.jpg" alt="" />
                      <div class="name">Sheeba</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>33</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            TC 46/1010/14, Badriyangar, Milk Colony Road,
                            Poonthura.P.O, Trivandrum
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\kolsam.jpg" alt="" />
                      <div class="name">Kolsam Beevi</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>47</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            Yasin Manzil, Venkode, Panachamoodu, Trivandrum
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\abdul.jpg" alt="" />
                      <div class="name">Abdul Rahiman</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>52</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            Palakkat Thodiyil House, Desamangalam P.O., Thrissur
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\Sivaraman.jpg" alt="" />
                      <div class="name">Sivaraman</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>50</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>Kadanjikulangara, Parlikkad, Thrissur</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\moitheen.jpg" alt="" />
                      <div class="name">Moitheenkutty</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>41</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            Chitakathu, Variyathayath.P.O, Thirunavaya,Thiruthy,
                            Malappuram
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\sathar.jpg" alt="" />
                      <div class="name">Sathar. P. S</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>33</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            Palliyally house, Murikungal.P.O, Kodaly, Thrissur
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\ayisha.jpg" alt="" />
                      <div class="name">Ayisha</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>3</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>Beema Manzil, Asharivila, Edacode, Nemom</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\shemila.jpg" alt="" />
                      <div class="name">Shemila.A</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>23</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>Thekkevila, Thazhamthekku, Chattanoor</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Alexander Samuel</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>56</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>Puthenparambil, Salempuram, Pathanapuram</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CAG</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\kamarudheen.jpg" alt="" />
                      <div class="name">Kamarudheen.S</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>34</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            Eettimoottil House, Kummannoor, Konni,
                            Pathanamthitta
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\maheen.jpg" alt="" />
                      <div class="name">Maheenkannu</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>35</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>Chippithottu Villa, Ramzamkulam, Vizhinjam</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CAG</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\mansu.jpg" alt="" />
                      <div class="name">Mansu.M.N</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>28</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            Nizam Manzil, Chippanchira, Karimankode, Palode
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CAG</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\amina.jpg" alt="" />
                      <div class="name">Aminakutty</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>52</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>Kadampully house, payoor, choonadal</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mr. Shamsudheen</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>39</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>Thekkekaliazhigam, Paravoor, Kollam</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\aysha.jpg" alt="" />
                      <div class="name">Aysha Rahim</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>47</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG X 4</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\devaysni.jpg" alt="" />
                      <div class="name">Devayani Sasi</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>48</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CAG,PTC,CABG</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\jameela.jpg" alt="" />
                      <div class="name">Jameela</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>65</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG X4</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Soudha Beevi</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>53</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>Irfan Villa, Umayanalloor PO, Kollam</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CAG,PTC,CABG</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\ra.jpg" alt="" />
                      <div class="name">Mr. Ratheesh Kumar V</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>53</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            Vattuvilakathu Veedu,ookkottukalliyoor PO, Tvm
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CAG</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\1.jpg" alt="" />
                      <div class="name">MASTER-JICKSON K JHONSON</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>11</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>Kanichamkunnathu, Pandimana (PO)</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>TOF</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>22/07/2008</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\2.jfif" alt="" />
                      <div class="name">MR.JAMSHEER ALI. K.K</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>23</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>KARAKONDIL, MALAPPURAMASD</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>14/08/2008</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\3.jfif" alt="" />
                      <div class="name">MR.APPUNNI</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>50</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            THEYYASEM CHERI, HOUSE KADAVANADU, PONNANI SOUTH
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG X2</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>9/4/2008</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\4.jfif" alt="" />
                      <div class="name">MR.UNNIKRISHNAN</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>24</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>PATTARU MADAM, KOMANA AMBALAPUZHA</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>18/09/2008</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">MR.RAJAN A S</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>54</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            ATTUPARAMBIL MURIKKALUMPURAM, MUNDAKKAYAM, KOTTAYAM
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABGX3</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>22/09/08</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">MR.RADHA KRISHNANKURUP</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>52</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>CHENNALOOR HOUSE PONNEZHA, OLAKETTIAMBALAM</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABGX3</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>30/09/08</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\5.jfif" alt="" />
                      <div class="name">SIDDIQUE</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>22</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>3/588 MATTANCHERI, COCHIN</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>12/15/2008</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\6.jfif" alt="" />
                      <div class="name">MRS.FATHIMA</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>31</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            CHEMBARTHIYAM PARMBU, KANICHAM CODE PO, PALAGHAT
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>23/01/09</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\7.jpg" alt="" />
                      <div class="name">MRS.ALHAYA</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>24</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>DEEPU NIVAS,PUTHEN KADA P O, CHAVARA KOLLAM</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>22/02/09</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\8.jfif" alt="" />
                      <div class="name">MASTER VIJIN KUMAR</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>6</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>KUMAR BHAVAN KULATTAMAL, CHAIKOTTUKONAM</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>18/03/09</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\9.jfif" alt="" />
                      <div class="name">MISS.REMYA</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>12</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>RAJAJI NAGAR, PUTHENCHANTHA TVM</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>21/04/09</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\10.jfif" alt="" />
                      <div class="name">HANEEFA</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>53</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            KULAPURATHU KONAM HOUSE,COLANI KUNNATHUKAL P O
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>3/7/2009</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\11.jfif" alt="" />
                      <div class="name">MASTER RENJITH</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>6</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>PUTHIYAVEETTIL HOUSE, VADANAPPALLY,THRISSUR</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>9/9/2009</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mr. JANSEER ALI</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>23</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>KARAKKONDIL HOUSE MALAPPURAM</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>14/8/08</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mr. APPUNNI</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>50</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>THEYYASEMCHERI KADAVANADU PONNANI, MALAPPURAM</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>04/9/08</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mr. UNNIKRISHNAN</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>24</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>PATTARUMADAM KOMANA,AMBALAPPUZHA</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>18/9/08</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mr. RADHAKRISHNAN</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>52</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>CHENNALOOR PONNEZHA OOLAKKETTILAMBALAM</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>30/9/08</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mr. SIDDIQUE</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>22</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>3/588, MATTANCHERI,KOCHI</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>5/12/08</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mrs. FATHIMA</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>31</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>CHEMBARATHIYAMVILA PALAKKADU</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>23/1/09</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mrs. ALAHYA</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>24</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>DEEPU NIVAS, PUTHUKAT, CHAVARA KOLLAM</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>25/2/09</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Master VIJIN KUMAR</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>6</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>KUMAR BHAVAN KULATHAM CHAIKKOTTUKONAM</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>18/3/09</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Ms. REMYA</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>12</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>RAJAJI NAGAR PUTHEN CHANTHA, TVM</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>21/4/09</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mr. HANEEFA</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>53</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>PUTHIYENVETTIL VADANAPPALLY THRISHUR</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>3/7/09</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Master RENJITH</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>10</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            KULAPURATHKONAM HOUSING COLONY KUNNATHUKAL.P.O. TVM
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>9/9/09</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Master ASHKAR</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>PALLINADAVEEDU SEENA HOUSE KOTTAPURAM</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>8/10/09</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Ms. MANJU</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>16</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>KALLAPPARAKKAL HOUSE VANDANMEDU.P.O. IDUKKI</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>14/12/09</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Baby AMANYA FATHIMA</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>SHAKINA NAGAR-126 KOLLAM</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>PDA CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>03/02/10</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mrs. DEVAYANI</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>48</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>APPACHATH THEKKUPURAM PARAVOOR</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>PERICARDIAL PATCH CLOSURE OF SEPTAE DEFECT</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>6/3/10</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mrs. SOUDHA BEEVI</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>59</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>IRFANVILA UMAYANALLOR.P.O. KOLLAM</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>5/5/10</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mr. CHANDRADAS</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>55</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>CHOTHI NIVAS KAKKAMUGAL KATTAKADA</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td></td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mr. GOVINDAN</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>57</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>KUZHIVILAMELEVEEDU KOLLODE MOOGODE</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>13/12/10</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mrs. JAMEELA</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>65</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            EDAVAZHIKKAL KALAVAMKODAM.P.O. CHERTHALA, AALAPPY
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>11/8/11</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mrs. AYSHA REHIM</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>47</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>KOLANKATHOTTU MOOLEPADAM ERANAKULAM</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>29/11/11</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mr. SHAMSUDEEN</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>39</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            THEKKEKALI EZHIGAL PONGAL, PARAVOOR.P.O. KOLLAM
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>28/1/12</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mrs. AMINAKUTTY</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>54</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>KADUMPALLY CHOONDAL, THRISHUR</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>17/10/12</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mrs. SHEMILA NOUSHAD</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>23</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>THEKKEVILA BANGLOW CHATHANOOR, KOLLAM</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>19/10/12</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Baby AYSHA</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>3</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>SEEMA MANZIL ASARVILLA, EDACODE NEMOM, TVM</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>10/12/12</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mr. KAMARUDEEN</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>34</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            ETHIMOOTTIL HOUSE UMANNOOR, KONNI PATHANAMTHITTA
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>15/12/12</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mr. MOIDEEN KUTTY</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>41</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>CHITTAKKATTIL THIRUNAVAYA MALAPPURAM</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>19/12/12</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mr. SHIVARAMAN</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>37</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>KUNJUKULANGARA VEEDU, VADAKKANCHERI THRISHUR</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>26/2/13</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mr. ABDUL RAHMAN</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>52</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>PALAKKATTUTHODIYIL THESHAMANGALAM THRISHUR</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>1/4/13</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mrs. KOLSAM BEEVI</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>48</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>YASEEN MANZIL PANACHAMOODU.P.O. K.K. DISTRICT</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>23/4/13</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mr. SHARAHABEEL</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>52</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>CHARUVILAI PUTHENVEEDU PALLIKAL</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>12/7/13</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mrs. SHEEBA</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>34</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            TC 46- 1010 (14) BADARIYA NAGAR MILK COLONY ROAD
                            POONTHURA.P.O. TVM
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>11/7/13</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mrs. SUHARA</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>49</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            THAYYIL THEKKATHIL AARYNADU SOUTH KULASEKHARAM
                            KARUNAGAPPALLY KOLLAM
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>12/8/13</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mrs. SAINABA</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>62</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>7/277, KOCHANGADI MATTANCHERY.P.O. KOCHI</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>9/10/13</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mr. SUBAIR</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>58</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            AL-ARIFA EAST OFF LHS AVALUKKUNNU.P.O. KOMALAPURAM
                            ALAPPUZHA
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>1/11/13</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\ismailkunju.jpg" alt="" />
                      <div class="name">Mr. ISMAIL KUNJU</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>48</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            (H2H) SHAHANA MANZIL CHERAVALLI KAYAMKULAM.P.O.
                            AALAPUZHA
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG X 5</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>22/11/13</td>
                        </tr>
                        <tr>
                          <td>Discharge Date</td>
                          <td>29/11/13</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\martinkp.jpg" alt="" />
                      <div class="name">Mr. MARTIN</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>41</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>KUTTUNGAL VENMALA.P.O. KOCHI</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG X 3</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>26/12/13</td>
                        </tr>
                        <tr>
                          <td>Discharge Date</td>
                          <td>03/01/14</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\amnasim.jpg" alt="" />
                      <div class="name">Mr. NAZIM</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>43</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            HOUSE NO: 28/1072 DHARMASHALA ROAD AMARAVATHI.P.O.
                            KOCHI
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>28/12/13</td>
                        </tr>
                        <tr>
                          <td>Discharge Date</td>
                          <td>04/01/14</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mr. YOONAS</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>48</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            PUTHENPARAMBIL VEEDU SASTHA TEMPLE ROAD KALOOR.P.O.
                            KOCHI
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>13/02/14</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3"></div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">MASTER AKHIL.A.KUMAR</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>15</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            CHARUVILA VEEDU KAYATTUKONAM KILIMANOOR.P.O.
                            TRIVANDRUM
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>22/04/14</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Ms. ATHIRA</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>23</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            KURUMBOLIN AAKAL BHAGAM, PANAMALA IDAPPALIKOTTA.P.O.
                            CHAVARA, KOLLAM
                          </td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD CLOSURE</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>13/05/14</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mr. SULAIMAN</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>63</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>KUNJU,MATHAMVILLA PADINJATAHIL,MULLIKAL</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>AVR /CABG</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>01/09/14</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mr. SIVAN</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>46</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>PUTHENPALLIYIL NAMBIAPURAM,COCHIN-6</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>21/10/14</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Child.Reeja</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>11</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>Palathadathil Veedu,Edacode</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ASD</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>20/05/15</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mrs Jameela</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>39</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>Nalliparambil, Kunnamkulam,Thrichur</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>MVR</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>31/01/17</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mr.Hassan P.U</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>55</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>Padathan west vengala, perumbavoor,Ernakulam</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>03/04/17</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">Mr.Rahman</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>57</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>Prakvithi Madathuvilakam,Vizhinjam</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>CABG</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>25/05/17</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="testimonial">
                      <img src="assets\img\home\patients\na.jpg" alt="" />
                      <div class="name">MASTER JIKSON</div>
                      <table border="1px" width="100%">
                        <tr>
                          <td>Age</td>
                          <td>11</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>KANICHAMKUNNATH PINDIMANA.P.O.</td>
                        </tr>
                        <tr>
                          <td>Surgery Name</td>
                          <td>ICR + TOF</td>
                        </tr>
                        <tr>
                          <td>Surgery Date</td>
                          <td>22/7/08</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Contact Us Section -->

  <div class="container-fluid contactus">
    <div class="toppad">
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <div class="contactdesc">
              <h4 class="text-uppercase font-weight-bold">
                FIND OUR <span style="color: #d72639">LOCATION</span>
              </h4>
              <hr style="width: 10%; text-align: left; margin-left: 0" />
            </div>
            <div class="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3946.892484568711!2d77.06372971398395!3d8.41221949395014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05af08ada78f17%3A0x266275e9e01868be!2sNIMS%20Hospital!5e0!3m2!1sen!2sin!4v1603134079392!5m2!1sen!2sin"
                width="100%" height="400" frameborder="0" style="border: 0" allowfullscreen="" aria-hidden="false"
                tabindex="0"></iframe>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="contactdesc">
              <h4 class="text-uppercase font-weight-bold">
                SEND US A <span style="color: #d72639"> MESSAGE</span>
              </h4>
              <hr style="width: 10%; text-align: left; margin-left: 0" />
            </div>
            <div class="contactform">
              <form>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label>First Name</label>
                    <input class="form-control" placeholder="Enter your first name" />
                  </div>
                  <div class="form-group col-md-6">
                    <label>last Name</label>
                    <input class="form-control" placeholder="Enter your Last name" />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label>Subject</label>
                    <input class="form-control" placeholder="Enter your Subject" />
                  </div>
                  <div class="form-group col-md-6">
                    <label>Phonenumber</label>
                    <input class="form-control" placeholder="Enter your Phonenumber" />
                  </div>
                </div>
                <div class="form-group">
                  <label for="comment">Message</label>
                  <textarea class="form-control" rows="5" id="comment" placeholder="Enter your Message"></textarea>
                </div>
                <button type="submit" class="btn btn-primary">
                  Send your message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>