import { Component, OnInit } from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { Appconstant } from "src/app/constant/app.constatnt";
import $ from "jquery";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  readMoreStatus: boolean = false;
  readMoreButtonStatus: boolean = true;

  readMoreStatus2: boolean = false;
  readMoreButtonStatus2: boolean = true;

  readMoreStatus3: boolean = false;
  readMoreButtonStatus3: boolean = true;

  appConstant: Appconstant = new Appconstant();
  imagePath: any;
  displayStyle = "none";

  constructor() {}

  ngOnInit() {
    this.imagePath = this.appConstant.DASHBORD_GALLERY;

    document.addEventListener("DOMContentLoaded", () => {
      function counter(id, start, end, duration) {
        let obj = document.getElementById(id),
          current = start,
          range = end - start,
          increment = end > start ? 1 : -1,
          step = Math.abs(Math.floor(duration / range)),
          timer = setInterval(() => {
            current += increment;
            obj.textContent = current;
            if (current == end) {
              clearInterval(timer);
            }
          }, step);
      }
      counter("count1", 0, 374, 4000);
      counter("count2", 4000, 5996, 4000);
      counter("count3", 38000, 42876, 4000);
      counter("count4", 8000, 10000, 4000);
      counter("count5", 0, 21, 4000);
    });

    this.openPopup();
  }

  readmore() {
    this.readMoreStatus = true;
    this.readMoreButtonStatus = false;
  }
  readmoreshowless() {
    this.readMoreButtonStatus = true;
    this.readMoreStatus = false;
  }

  readmore2() {
    this.readMoreStatus2 = true;
    this.readMoreButtonStatus2 = false;
  }
  readmoreshowless2() {
    this.readMoreButtonStatus2 = true;
    this.readMoreStatus2 = false;
  }

  readmore3() {
    this.readMoreStatus3 = true;
    this.readMoreButtonStatus3 = false;
  }
  readmoreshowless3() {
    this.readMoreButtonStatus3 = true;
    this.readMoreStatus3 = false;
  }
  openPopup() {
    this.displayStyle = "block";
  }
  closePopup() {
    this.displayStyle = "none";
  }
}
